import { iViewPortalDirectusUser } from '~/interfaces'

/* export const useUserLoadTest = () => {
	return {
		user: null,
		urlCallback: '',
		loadUserFromSession: async () => {
			try {
			/* const session = await getSession()
			if (session) {
				this.user = session.user as iViewPortalDirectusUser
				const _urlCallback = this.user?.customization?.app_url || '/'
				this.urlCallback = `/${_urlCallback}/login`
				useThemeStore().setOptionFromUser(this.user?.customization?.theme_colors || '')
			} else {
				console.log('No user session')
			} *
			} catch (error) {
				console.error('Failed to load user:', error)
			}
		}

	}
} */
let user: iViewPortalDirectusUser | null
let urlCallback = ''
// export const stateUserCss = () => useState<string>('linkcss')

export const useUserSessionManagement = () => {
	const getUser = () => {
		// console.log('GET USER STORE:', user)
		if (user === null) { return null }
		return user
	}

	const loadUserFromSession = async () => {
		try {
			const session = await useAuth().getSession()
			if (session) {
				user = session.user as iViewPortalDirectusUser
				const _urlCallback = user?.customization?.app_url || '/'
				urlCallback = `/${_urlCallback}/login`
				// console.log('loadUserFromSession:', user?.customization?.image_collections || '')

				//  12 mag 2024, provo ad anticipare il caricamento del tema
				useThemeStore().setOptionFromUser(user?.customization?.theme_colors || '')
				useThemeStore().setImageCollectionsFromUser(user?.customization?.image_collections || '')
				// useCompanyStore().loadUser()
			} else {
				console.log('No user session')
			}
		} catch (error) {
			console.error('Failed to load user:', error)
		}
	}
	const setUser = (_user: iViewPortalDirectusUser | null) => {
		console.log('SET _USER STORE:', _user)

		user = _user
		console.log('SET USER STORE:', user)
	}
	const setUrlCallback = (_urlCallback: string) => {
		urlCallback = _urlCallback
	}

	return {
		user,
		urlCallback,
		loadUserFromSession,
		// setUser,
		getUser
	}
}
