<script setup lang='ts'>
import { computed, watch, ref } from 'vue'

import AppMenu from '~/components/app/AppMenu.vue'
import { iViewPortalDirectusUser } from '~/interfaces'
const { layoutConfig, layoutState, isSidebarActive } = useLayout()
const outsideClickListener = ref(null)
const themeStore = useThemeStore()

/* // in modifica per color template da nero a bianco che sfarfalla
//const coockies = useCookie('theme_colors')
const linkCss = ref('')
const getUSerSession = async () => {
	const session = await useAuth().getSession()
	console.log('default process is server: ', process.server, ' linkCss: ', linkCss,	' session user is: ', session.user)

	if (session) {
		const user = session.user as iViewPortalDirectusUser
		if (user?.customization?.theme_colors) {
			themeStore.setOptionFromUser(user.customization.theme_colors)
			linkCss.value = themeStore.link
			coockies.value = themeStore.link
			console.log('default getUSerSession linkCss.value: ', linkCss.value)
		}
	} else {
		console.log('!!!default getUSerSession linkCss.value gia valorizzato: ', linkCss)
	}
}
 getUSerSession().then(() => {
	console.log('default getUSerSession: ', themeStore.link)
}) */
onMounted(async () => {
	await useUserSessionManagement().loadUserFromSession()
	// console.log('default onMounted coockies: ', coockies.value)
})

watch(isSidebarActive, (newVal) => {
	if (newVal) {
		bindOutsideClickListener()
	} else {
		unbindOutsideClickListener()
	}
})
const containerClass = computed(() => {
	return {
		'layout-theme-light': layoutConfig.darkTheme.value === 'light',
		'layout-theme-dark': layoutConfig.darkTheme.value === 'dark',
		'layout-overlay': layoutConfig.menuMode.value === 'overlay',
		'layout-static': layoutConfig.menuMode.value === 'static',
		'layout-static-inactive': layoutState.staticMenuDesktopInactive.value && layoutConfig.menuMode.value === 'static',
		'layout-overlay-active': layoutState.overlayMenuActive.value,
		'layout-mobile-active': layoutState.staticMenuMobileActive.value,
		'p-input-filled': layoutConfig.inputStyle.value === 'filled',
		'p-ripple-disabled': !layoutConfig.ripple.value
	}
})

const bindOutsideClickListener = () => {
	if (!outsideClickListener.value) {
		outsideClickListener.value = (event) => {
			if (isOutsideClicked(event)) {
				layoutState.overlayMenuActive.value = false
				layoutState.staticMenuMobileActive.value = false
				layoutState.menuHoverActive.value = false
			}
		}

		document.addEventListener('click', outsideClickListener.value)
	}
}

const unbindOutsideClickListener = () => {
	if (outsideClickListener.value) {
		document.removeEventListener('click', outsideClickListener)
		outsideClickListener.value = null
	}
}

const isOutsideClicked = (event) => {
	const sidebarEl = document.querySelector('.layout-sidebar')
	const topbarEl = document.querySelector('.layout-menu-button')

	return !(sidebarEl.isSameNode(event.target) || sidebarEl.contains(event.target) || topbarEl.isSameNode(event.target) || topbarEl.contains(event.target))
}
const getLogoHomeCompose = computed(() => {
	return themeStore.getLoadLogoHome
})
</script>

<template>
  <!-- <div v-if="!themeStore.link">
    <div class="p-d-flex p-jc-center p-ai-center" style="height: 100vh">
      <ProgressBar mode="indeterminate" style="height: 16px" class="border-2" />
      <ProgressSpinner />
    </div>
  </div> -->
  <div>
    <Link rel="stylesheet" :href="themeStore.link || 'https://cdn.jsdelivr.net/npm/primevue@3.15.0/resources/themes/saga-green/theme.min.css'" />
    <!--  <Link rel="stylesheet" :href="coockies || 'https://cdn.jsdelivr.net/npm/primevue@3.15.0/resources/themes/saga-green/theme.min.css'" /> -->

    <!-- <link rel="stylesheet" :href="linkCss || 'https://cdn.jsdelivr.net/npm/primevue@3.15.0/resources/themes/vela-blue/theme.css'">
		https://cdn.jsdelivr.net/npm/primevue@3.52.0/resources/primevue.min.css

	-->

    <!-- <Link rel="stylesheet" :href="themeStore.link || 'https://cdn.jsdelivr.net/npm/primevue@3.15.0/resources/themes/saga-green/theme.css'" /> -->

    <div class="layout-wrapper " :class="containerClass">
      <!--v-show="getLogoHomeCompose"-->
      <app-topbar />
      <div v-if="getLogoHomeCompose" class="layout-sidebar w-27rem" :class="!themeStore.isDarkMode ? 'bg-primary-800 text-white' : 'surface-ground text-color'">
        <app-menu />
      </div>
      <!--      <Sidebar v-model:visible="userCompose().openMenu.value" header="Right Sidebar" position="right">
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
      </Sidebar> -->
      <!-- :class="!themeStore.isDarkMode ? 'bg-primary-800 text-white' : 'surface-ground text-color'"

	-->
      <div
        class="layout-main-container pb-0"
        style="margin-left: 0px;"
        :style="themeStore.link ? !themeStore.isDarkMode ? 'background-color: #e7e7e7' : 'background-color: #17212f' :	'background-color: #e7e7e7'"
      >
        <img
          id="top_left_content_image"
          :src="themeStore.isDarkMode ? '/images/home_icone/home_deleganoi_transparent_dark.png' : '/images/home_icone/home_deleganoi_transparent_white.png'"
          alt="Image"
          class="fixed top-20 left-0 h-16rem"
        >

        <div class="layout-main" style="z-index: 1;margin-bottom: 5rem;">
          <slot />
        </div>
        <img
          id="bottom_right_content_image"
          :src="themeStore.isDarkMode ? '/images/home_icone/R_background_bottom_right_tema_scuro.png' : '/images/home_icone/R_background_bottom_right_tema_chiaro.png'"
          alt="Image"
          class="fixed border-0 right-0 bottom-0"
          style="z-index: 0; height: 300px; "
        ><app-footer />
      </div>
      <div class="layout-mask hidden" />
    </div>
  </div>
</template>

<style lang="scss" scoped></style>
